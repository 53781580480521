<template>
  <b-container class="main-content-container px-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="12">
        <h3 class="page-title">{{ $t('support.title_card') }}</h3>
        <span class="text-uppercase page-subtitle">{{ $t('support.title') }}</span>
      </b-col>
    </b-row>
    <b-card no-body>
      <SupportChat ref="chat"
                   show-asign-me="true" @on-load="support = $event"/>
    </b-card>
  </b-container>
</template>

<script>
import SupportChat from '@/views/dashboard/support/support-chat/index.vue';
import {decrypt} from "@/helpers/encrypt";

export default {
  name: 'Chat',
  components: {SupportChat},
  data: () => ({
    id: null,
    support: null
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.checkUrlId()
    setTimeout(() => {
      this.$refs.chat.loadSupport(this.id);
    }, 500)
  },
  watch: {
    '$route.params.id'() {
      this.id = this.$route.params.id;
      this.checkUrlId()
      setTimeout(() => {
        this.$refs.chat.loadSupport(this.id);
      }, 500)
    },
  },
  methods: {
    checkUrlId() {
      try {
        if (typeof this.id === 'string' && !!this.id) {
          const dec = decrypt(this.id)
          this.id = JSON.parse(dec).id
        }
      } catch (e) {
        this.$router.push("/error")
       setTimeout(()=>{
         location.reload()
       },1000)
      }
    },
  }
};
</script>

<style scoped>

</style>
